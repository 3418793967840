var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}]},[_c('b-card',{staticClass:"mb-0"},[_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":"/logo.png","width":"150"}}),_c('h3',[_vm._v("Register New School")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" Go to Portal ")])],1),_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"circle","step-size":"xs","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"School Information","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" School Full Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Full Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Example: Lekki British School"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Abbreviated Name","label-for":"slug"}},[_c('validation-provider',{attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","placeholder":"Example: LBS","state":errors.length > 0 ? false:null},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Official Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Official Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter Official Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Website","label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","placeholder":"Enter School Website"},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Estimated number of students","label-for":"estimated_no_of_students"}},[_c('validation-provider',{attrs:{"name":"Estimated number of students"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select No. of Student"},model:{value:(_vm.form.estimated_no_of_students),callback:function ($$v) {_vm.$set(_vm.form, "estimated_no_of_students", $$v)},expression:"form.estimated_no_of_students"}},_vm._l((_vm.estimated_no_of_students),function(no_of_student,index){return _c('el-option',{key:index,attrs:{"label":no_of_student,"value":no_of_student}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Curriculum","label-for":"curriculum"}},[_c('validation-provider',{attrs:{"name":"School Curriculum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select School Curriculum"},model:{value:(_vm.form.curriculum),callback:function ($$v) {_vm.$set(_vm.form, "curriculum", $$v)},expression:"form.curriculum"}},[_c('el-option',{attrs:{"label":"Nigerian","value":"Nigerian"}}),_c('el-option',{attrs:{"label":"British","value":"British"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"School Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","placeholder":"Enter School Address","state":errors.length > 0 ? false:null},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country","options":_vm.countries,"label":"country_name"},on:{"input":function($event){return _vm.setState()}},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.states.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State of Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"State of Location","label-for":"state","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"state","options":_vm.states,"label":"name"},on:{"input":function($event){return _vm.setLgas()}},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3318720129)})],1):_vm._e(),(_vm.lgas.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"LGA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"LGA of Location","label-for":"lga","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"lga","options":_vm.lgas,"label":"name"},on:{"input":function($event){return _vm.setLgas()}},model:{value:(_vm.selectedLGA),callback:function ($$v) {_vm.selectedLGA=$$v},expression:"selectedLGA"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3252825180)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tick As Appropriate"}},[_c('b-form-checkbox',{attrs:{"value":true,"name":"flavour-3a"},model:{value:(_vm.form.nursery),callback:function ($$v) {_vm.$set(_vm.form, "nursery", $$v)},expression:"form.nursery"}},[_vm._v(" Has Nursery ")]),_c('b-form-checkbox',{attrs:{"value":true,"name":"flavour-3a"},model:{value:(_vm.form.pry),callback:function ($$v) {_vm.$set(_vm.form, "pry", $$v)},expression:"form.pry"}},[_vm._v(" Has Primary ")]),_c('b-form-checkbox',{attrs:{"value":true,"name":"flavour-3a"},model:{value:(_vm.form.secondary),callback:function ($$v) {_vm.$set(_vm.form, "secondary", $$v)},expression:"form.secondary"}},[_vm._v(" Has Secondary ")])],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"School Administrator Information","before-change":_vm.validationFormAdministrator}},[_c('validation-observer',{ref:"administratorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Administrator Details ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null},model:{value:(_vm.form.admin_first_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_first_name", $$v)},expression:"form.admin_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null},model:{value:(_vm.form.admin_last_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_last_name", $$v)},expression:"form.admin_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.form.admin_email),callback:function ($$v) {_vm.$set(_vm.form, "admin_email", $$v)},expression:"form.admin_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-primary"},[_c('strong',[_vm._v("On successful registration a notification will be sent to this e-mail")])])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Gender"},model:{value:(_vm.form.admin_gender),callback:function ($$v) {_vm.$set(_vm.form, "admin_gender", $$v)},expression:"form.admin_gender"}},_vm._l((_vm.genders),function(gender,index){return _c('el-option',{key:index,attrs:{"label":gender,"value":gender}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Primay Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Primay Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter Phone Number"},model:{value:(_vm.form.admin_phone1),callback:function ($$v) {_vm.$set(_vm.form, "admin_phone1", $$v)},expression:"form.admin_phone1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alternative Mobile Number","label-for":"phone2","rules":"integer:min:11|integer:max:11"}},[_c('b-form-input',{attrs:{"id":"phone2","placeholder":"Enter Alternative Phone Number"},model:{value:(_vm.form.admin_phone2),callback:function ($$v) {_vm.$set(_vm.form, "admin_phone2", $$v)},expression:"form.admin_phone2"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }